import React, { useCallback } from 'react';
import { Drawer, useTheme, useMediaQuery } from '@mui/material';

interface SimpleDrawerProps {
  onClose: () => void;
  isOpen: boolean;
  anchor?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large' | 'auto';
  strictClose?: boolean;
  fullMobileWidth?: boolean;
  children: React.ReactNode;
}

const ON_CLOSE_LIGHT_REASONS = ['backdropClick', 'escapeKeyDown'];

export default function SimpleDrawer({
  onClose,
  isOpen,
  anchor = 'right',
  size = 'medium',
  strictClose = true,
  fullMobileWidth = true,
  children,
}: SimpleDrawerProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const getWidth = () => {
    const widthMap = {
      small: '150px',
      medium: '250px',
      large: '450px',
      auto: 'auto',
    };

    if (isMobile) {
      return fullMobileWidth ? '100%' : 'calc(100% - 60px)';
    }

    return widthMap[size];
  };
  const handleClose = useCallback(
    (e: any, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (e) {
        console.log(e);
      }

      if (strictClose && ON_CLOSE_LIGHT_REASONS.includes(reason)) {
        return;
      }

      onClose();
    },
    [onClose, strictClose],
  );

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: getWidth(),
        },
      }}
    >
      {children}
    </Drawer>
  );
}
