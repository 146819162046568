import React, {useEffect} from 'react';
import {Badge} from '@mui/material';
import {RiAccountCircleFill} from 'react-icons/ri';
import {useQuery} from 'react-query';

import BadgeTooltip from '@/common/components/tooltips/BadgeTooltip';
import {queries} from '@/common/api/operations';
import {useUserContext} from '@/common/contexts/UserContext';
import {useApiContext} from '@/common/contexts/ApiContext';

export default function AdminBadge() {
  const {user} = useUserContext();
  const userContext = useUserContext();
  const {apiClient} = useApiContext();

  const countMissingElements = user?.countMissingElements ?? 0;
  const isMissingErrors = user?.isMissingErrors;

  const propertytotalQuery = useQuery(queries.pendingtotal.get, () =>
    apiClient.pendingtotal.get({}),
  );

  const pendingResult =
    propertytotalQuery?.data?.pendingTotal?.[0]?.[0].grand_tot ?? 0;
  const isSubscription = userContext?.user?.isSubscription ?? false;
  const isDelinquent = user?.isDelinquent;

  useEffect(() => {
    propertytotalQuery.refetch();
  }, []);

  const badgeContent = isSubscription ? 'Subscribed' : 'Admin';
  const subscriptionColor = isDelinquent ? 'error' : 'warning';
  const missingErrorsColor = isMissingErrors ? 'error' : 'warning';

  return (
    <Badge
      badgeContent={badgeContent}
      sx={{
        fontSize: 10,
        '& > .MuiBadge-badge': {
          backgroundColor: '#2e7d32',
          color: 'white',
          left: '50%',
          fontSize: '10px',
        },
      }}
      color={isSubscription ? subscriptionColor : 'secondary'}
      overlap="circular"
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
    >
      <Badge
        badgeContent={pendingResult}
        color="error"
        overlap="circular"
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {pendingResult > 0 && (
          <BadgeTooltip message="Admin Errors" direction="left" />
        )}

        <Badge
          badgeContent={countMissingElements}
          sx={{
            '& .MuiBadge-badge': {
              color: 'white',
            },
          }}
          color={missingErrorsColor}
          overlap="circular"
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          {countMissingElements > 0 && (
            <BadgeTooltip message="Rightsmage Errors" direction="right" />
          )}
          <RiAccountCircleFill size={48} color="orange" />
        </Badge>
      </Badge>
    </Badge>
  );
}
