import { useCallback } from 'react';
import { debounce } from '@mui/material/utils';

export function useDebounced<T extends (...args: any[]) => any>(
  fn: T,
  wait: number,
  deps: unknown[],
): (...args: Parameters<T>) => void {
  return useCallback(debounce(fn, wait), deps);
}
