import React from 'react';
import {useRouter} from 'next/router';
import {RiAccountCircleFill} from 'react-icons/ri';
import {useTheme} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import {useUserContext} from '@/common/contexts/UserContext';
import {appRoutes, resolveImageUrl} from '@/common/config';

interface UserBadgeProps {
  user: any;
  listingId: number | null;
  size?: string;
}

export default function UserBadge({
  size = '',
  user,
  listingId,
}: UserBadgeProps) {
  const {push} = useRouter();
  const userContext = useUserContext();
  const theme = useTheme();

  // Get User's parameters
  const isSubscription = userContext?.user?.isSubscription ?? false;
  const countMissingElements = userContext?.user?.countMissingElements;
  const isMissingErrors = user?.isMissingErrors;

  // Prepare parameters
  const badgeContent = isSubscription ? 'Subscribed' : null;
  const circleSize = size === 'large' ? 60 : 48;
  const missingErrorsColor = isMissingErrors ? 'error' : 'warning';

  return (
    <Badge
      badgeContent={badgeContent}
      sx={{
        fontSize: 10,
        '& > .MuiBadge-badge': {
          backgroundColor: '#2e7d32',
          color: 'white',
          left: '50%',
          fontSize: '10px',
        },
      }}
      color="warning"
      overlap="circular"
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
    >
      <Badge
        badgeContent={countMissingElements}
        sx={{
          '& .MuiBadge-badge': {
            color: 'white',
          },
        }}
        color={missingErrorsColor} // 'error' or 'warning'
        overlap="circular"
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        {user?.listingImageId ? (
          <img
            src={resolveImageUrl(user.listingImageId)}
            onClick={() => {
              push(appRoutes.account.listing(listingId ?? 'new'));
            }}
            style={{
              cursor: 'pointer',
              width: circleSize,
              height: circleSize,
              borderRadius: '50%',
            }}
            alt="User Listing"
          />
        ) : (
          <RiAccountCircleFill
            size={circleSize}
            color={theme.palette.primary.main}
          />
        )}
      </Badge>
    </Badge>
  );
}
