// Outer
import React, {useState, useEffect} from 'react';
import Link from 'next/link';
import {alpha, useTheme} from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Backdrop,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemButton,
  Autocomplete,
} from '@mui/material';
import {GiHamburgerMenu} from 'react-icons/gi';
import {
  FaHashtag,
  FaUserPlus,
  FaRegAddressBook,
  FaMoneyBillAlt,
} from 'react-icons/fa';
import {
  VscRepo,
  VscRepoPull,
  VscRepoPush,
  VscHome,
  VscVerified,
  VscUnverified,
  VscSettingsGear,
  VscSmiley,
  VscLiveShare,
  VscCloudUpload,
  VscEdit,
  VscRocket,
  VscDashboard,
  VscLibrary,
  VscLink,
} from 'react-icons/vsc';
import {FiUsers} from 'react-icons/fi';
import {BsFillPersonLinesFill} from 'react-icons/bs';
import {AiOutlineSearch} from 'react-icons/ai';
import {BiLogOutCircle, BiLogInCircle} from 'react-icons/bi';
import {
  RiAccountBoxFill,
  RiHandCoinLine,
  RiShoppingCart2Line,
  RiShoppingBasketLine,
} from 'react-icons/ri';
import {VscSettings, VscGraph} from 'react-icons/vsc';
import {TiArrowSortedDown, TiArrowSortedUp} from 'react-icons/ti';
import {
  Box,
  Tab,
  Tabs,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useRouter} from 'next/router';
import {useQuery} from 'react-query';

// Inner
import useQueryParams from '@/common/hooks/useQueryParams';
import SimpleDrawer from '@/common/components/SimpleDrawer';
import AdminBadge from '@/common/components/AdminBadge';
import UserBadge from '@/common/components/UserBadge';
import Badge from '@mui/material/Badge';
import {appRoutes, resolveImageUrl} from '@/common/config';
import {queries} from '@/common/api/operations';
import {useUserContext} from '@/common/contexts/UserContext';
import {useApiContext} from '@/common/contexts/ApiContext';
import {useSearchListingsAndHashtagsAutocomplete} from '@/admin/features/autocomplete/useSearchListingsAndHashtagsAutocomplete';
import {useGlobalContext} from '@/common/contexts/GlobalContext';

const planList = {
  one: 'rightsmage basic',
  two: 'rightsmage pro',
  three: 'rightsmage small press',
  four: 'rightsmage publisher',
};

interface HeaderBarProps {
  hideSearch?: boolean;
  hideMenu?: boolean;
}

const useUserDelegation = (
  list: Array<any>,
  settingsList: Array<any>,
  email: string,
  userId: string,
) => {
  if (!list?.length || !settingsList?.length) return [];

  const userCompanySetting = settingsList.filter(
    item => item.userId === userId,
  );

  const initDefaultProfile = {
    companyName: userCompanySetting?.[0]?.companyName ?? 'Default Profile',
    userId: '',
    id: Date.now(),
  };
  const delegation = list?.filter((item: any) => item.email === email);
  const prepareDelegation = delegation.map((item: any) => {
    const companySetting = settingsList.find(
      (i: any) => i.id === item.companySettingsId,
    );

    return {
      ...item,
      companyName: companySetting.companyName || 'No Name',
    };
  });

  if (prepareDelegation && prepareDelegation?.length === 0) {
    return [];
  }

  return initDefaultProfile
    ? [initDefaultProfile, ...prepareDelegation]
    : prepareDelegation;
};

export default function HeaderBar({
  hideMenu = false,
  hideSearch = false,
}: HeaderBarProps) {
  const {siteName, siteLogoURL} = useGlobalContext();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(() => {
    setIsDrawerOpen(val => !val);
  }, []);
  const {
    isSearching,
    isSearchingOptions,
    searchText,
    autocompleteResults,
    handleSearchTextChange,
    handleSelectText,
  } = useSearchListingsAndHashtagsAutocomplete();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {push} = useRouter();
  const {categoryName} = useQueryParams();
  const {apiClient} = useApiContext();
  const result = useQuery(queries.search.menuItems, apiClient.search.menuItems);
  const menuItems = result.data?.menuItems ?? [];
  const handleTabChange = (e: React.ChangeEvent<{}>, tabIndex: number) => {
    const item = menuItems[tabIndex];
    push(appRoutes.home.category(item.name));
  };
  const tabIndex = menuItems.findIndex(item => item.name === categoryName);
  const {
    user,
    isLoggedIn,
    listingId,
    isAdmin,
    logout,
    setUserId,
    isDelegatedSubscription,
  } = useUserContext();
  const email = user?.email ?? '';
  const userId = user?.id ?? '';

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const delegatedId = user?.delegatedId || '';
  const isSubscriptionValue =
    isDelegatedSubscription === null
      ? !!user?.isSubscription
      : isDelegatedSubscription;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const countMissingEmailRightsholder =
    user?.countMissingEmailRightsholder || 0;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const countMissingProperty = user?.countMissingProperty || 0;
  const isDelinquent = user?.isDelinquent;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isCompanySettings = user?.isCompanySettings || 0;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const plan = user?.plan?.toLowerCase() || '';

  const [currentProfile, setCurrentProfile] = useState(delegatedId);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({
    admin: true,
    rightsmage: false,
  });

  const toggleMenu = (key: string) => {
    setIsMenuOpen(() => {
      const initState = {
        admin: false,
        rightsmage: false,
      };
      return {
        ...initState,
        [key]: true,
      };
    });
  };

  const companySettingsDelegationQuery = useQuery(
    queries.companysettingsdelegation.get,
    () => apiClient.companysettingsdelegation.get(),
  );
  const allDelegation = companySettingsDelegationQuery.data;

  const companySettingsQuery = useQuery(queries.companySettings.get, () =>
    apiClient.companySettings.get(),
  );
  const allCompanySettings = companySettingsQuery.data;
  const currentDelegation = useUserDelegation(
    allDelegation,
    allCompanySettings,
    email,
    userId,
  );

  useEffect(() => {
    if (
      !isAdmin &&
      currentDelegation?.length > 0 &&
      currentDelegation[0].userId
    ) {
      setCurrentProfile(currentDelegation[0].userId);
      setUserId(currentDelegation[0].userId);
    }
  }, [email]);

  const handleProfiles = (e: any) => {
    const target = e.target;

    setCurrentProfile(target.value);
    setUserId(target.value);

    if (!isAdmin && target.value === '') {
      push(appRoutes.home.root);
    }
  };

  return (
    <>
      <AppBar
        sx={{
          background: '#24262D',
        }}
        position="static"
      >
        <Toolbar>
          <Backdrop style={{color: '#fff', zIndex: 100}} open={isSearching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            sx={{
              display: 'block',
              [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
              },
            }}
            width="100%"
            pt={1}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <IconButton
                  edge="start"
                  sx={{
                    color: 'text.primary',
                    marginRight: 2,
                  }}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                >
                  <GiHamburgerMenu />
                </IconButton>

                <Link
                  href={appRoutes.home.root}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: theme.palette.text.primary,
                      [theme.breakpoints.up('sm')]: {
                        display: 'block',
                      },
                      [theme.breakpoints.down('sm')]: {
                        display: hideSearch ? 'block' : 'none',
                      },
                    }}
                    variant="h6"
                    noWrap
                  >
                    {siteName}
                  </Typography>
                </Link>
              </Box>
              {!hideSearch && (
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      position: 'relative',
                      borderRadius: '4px',
                      backgroundColor: alpha(theme.palette.common.white, 0.15),
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.common.white, 0.2),
                      },
                      display: 'flex',
                      alignContent: 'center',
                      marginRight: theme.spacing(2),
                      marginLeft: 0,
                      width: '100%',
                      [theme.breakpoints.up('sm')]: {
                        marginLeft: theme.spacing(4),
                        width: 'auto',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        color: 'text.primary',
                        padding: theme => theme.spacing(0, 1),
                        pointerEvents: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AiOutlineSearch size={20} />
                    </Box>

                    <Autocomplete
                      open={isAutocompleteOpen}
                      freeSolo
                      disableClearable
                      id="search-autocomplete"
                      options={autocompleteResults}
                      sx={{
                        width: '180px',
                      }}
                      onOpen={() => {
                        if (searchText) {
                          setIsAutocompleteOpen(true);
                        }
                      }}
                      onClose={() => setIsAutocompleteOpen(false)}
                      loading={isSearchingOptions}
                      onChange={handleSelectText}
                      inputValue={searchText}
                      onInputChange={handleSearchTextChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          size="small"
                          label="Search..."
                          variant="filled"
                          sx={{
                            background: 'transparent !important',
                            '& .MuiFilledInput-root': {
                              background: 'transparent !important',
                            },
                            '& .MuiInputBase-inputTypeSearch': {
                              WebkitAppearance: 'none',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            disableUnderline: true,
                            endAdornment: (
                              <React.Fragment>
                                {isSearchingOptions && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            <Box display="flex" alignItems="center">
              {/* Select Delegated Profile */}
              {isLoggedIn && currentDelegation?.length > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    paddingTop: '15px',
                    paddingBottom: '5px',
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                      width: '200px',
                      paddingTop: '0',
                      marginRight: '20px',
                    },
                    [theme.breakpoints.up('lg')]: {
                      width: '300px',
                    },
                  }}
                >
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="currentProfile">Profiles</InputLabel>
                    <Select
                      labelId="currentProfile"
                      id="currentProfile"
                      name="currentProfile"
                      value={currentProfile}
                      fullWidth
                      onChange={handleProfiles}
                      variant="outlined"
                    >
                      {currentDelegation?.map((item: any) => (
                        <MenuItem key={item.userId} value={item.userId}>
                          {item.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {/* users */}
              {isDesktop && isLoggedIn && !isAdmin && (
                <Box display="flex" alignItems="center">
                  <UserBadge user={user} listingId={listingId} />

                  <Box display="flex" flexDirection="column" marginLeft={1}>
                    <Typography
                      variant="subtitle2"
                      color="textPrimary"
                      onClick={() => {
                        push(appRoutes.account.listing(listingId ?? 'new'));
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      {user?.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      onClick={() => {
                        push(appRoutes.account.listing(listingId ?? 'new'));
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      {user?.email}
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* admins */}
              {isDesktop && isLoggedIn && isAdmin && (
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    {user?.listingImageId ? (
                      <img
                        src={resolveImageUrl(user.listingImageId)}
                        style={{
                          width: '48px',
                          height: '48px',
                          borderRadius: '50%',
                        }}
                      />
                    ) : (
                      <AdminBadge />
                    )}

                    <Box display="flex" flexDirection="column" marginLeft={1}>
                      <Typography variant="caption" color="textPrimary">
                        {user?.name}
                      </Typography>
                      <Typography variant="caption" color="textPrimary">
                        {user?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
        {!hideMenu && (
          <Tabs
            value={tabIndex === -1 ? false : tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTabScrollButton-root': {
                color: 'text.primary',
              },
            }}
          >
            {menuItems.map(item => (
              <Tab
                key={item.id}
                label={item.name}
                sx={{
                  textTransform: 'none',
                }}
              />
            ))}
          </Tabs>
        )}
      </AppBar>

      <SimpleDrawer
        anchor="left"
        strictClose={false}
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        fullMobileWidth={false}
      >
        {isLoggedIn && (
          <>
            <List>
              {/* users */}
              {!isAdmin && (
                <ListItemButton>
                  <UserBadge size={'large'} user={user} listingId={listingId} />

                  <Box display="flex" flexDirection="column" marginLeft={1}>
                    <Typography variant="subtitle2">{user?.name}</Typography>
                    <Typography variant="caption">{user?.email}</Typography>
                  </Box>
                </ListItemButton>
              )}

              {/* admins */}
              {isAdmin && (
                <ListItemButton>
                  {user?.listingImageId ? (
                    <img
                      src={resolveImageUrl(user.listingImageId)}
                      style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '50%',
                      }}
                    />
                  ) : (
                    <AdminBadge />
                  )}
                  <Box display="flex" flexDirection="column" marginLeft={1}>
                    <Typography variant="subtitle2">{user?.name}</Typography>
                    <Typography variant="caption">{user?.email}</Typography>
                  </Box>
                </ListItemButton>
              )}
              {/* <List>
                <ListItemButton
                  onClick={() => {
                    push(appRoutes.home.root);
                    toggleDrawer();
                  }}
                >
                  <ListItemIcon>
                    <VscHome size={24} color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
                {isLoggedIn && (
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.subscriptions);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscRocket size={24} color={theme.palette.success.main} />
                    </ListItemIcon>
                    <ListItemText primary="Subscriptions" />
                  </ListItemButton>
                )}
              </List>
              <Divider /> */}
              {!isAdmin && (
                <>
                  <Divider />
                  <ListItemButton onClick={toggleMenu.bind(null, 'admin')}>
                    <ListItemText primary="BookMage" />
                    {isMenuOpen.admin ? (
                      <TiArrowSortedDown>
                        <VscSmiley
                          size={24}
                          color={theme.palette.success.light}
                        />
                      </TiArrowSortedDown>
                    ) : (
                      <TiArrowSortedUp>
                        <VscSmiley
                          size={24}
                          color={theme.palette.success.light}
                        />
                      </TiArrowSortedUp>
                    )}
                  </ListItemButton>

                  {isMenuOpen.admin && (
                    <>
                      <ListItemButton
                        onClick={() => {
                          push(appRoutes.home.root);
                          toggleDrawer();
                        }}
                      >
                        <ListItemIcon>
                          <VscHome size={24} color="white" />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItemButton>

                      <ListItemButton
                        onClick={() => {
                          push(appRoutes.account.listing(listingId ?? 'new'));
                          toggleDrawer();
                        }}
                      >
                        <ListItemIcon>
                          <RiAccountBoxFill
                            size={24}
                            color={theme.palette.primary.main}
                          />
                        </ListItemIcon>
                        <ListItemText primary="My Account" />
                      </ListItemButton>
                      {isLoggedIn && (
                        <ListItemButton
                          onClick={() => {
                            push(appRoutes.admin.subscriptions);
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <Badge
                              badgeContent={isDelinquent ? 'Unpaid' : 0}
                              color={'error'}
                              showZero={false}
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <VscRocket
                                size={24}
                                color={theme.palette.success.main}
                              />
                            </Badge>
                          </ListItemIcon>
                          <ListItemText primary="Subscriptions" />
                        </ListItemButton>
                      )}
                    </>
                  )}
                </>
              )}
            </List>
            <Divider />
          </>
        )}
        {isAdmin && (
          <>
            <List>
              <ListItemButton onClick={toggleMenu.bind(null, 'admin')}>
                <ListItemText primary="Admin" />
                {isMenuOpen.admin ? (
                  <TiArrowSortedDown>
                    <VscSmiley size={24} color={theme.palette.success.light} />
                  </TiArrowSortedDown>
                ) : (
                  <TiArrowSortedUp>
                    <VscSmiley size={24} color={theme.palette.success.light} />
                  </TiArrowSortedUp>
                )}
              </ListItemButton>

              {isMenuOpen.admin && (
                <>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.users());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <FiUsers size={24} color={theme.palette.primary.main} />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.siteSettings());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscSettingsGear
                        size={24}
                        color={theme.palette.warning.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Site Settings" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.listings());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscSmiley
                        size={24}
                        color={theme.palette.success.light}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Listings" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.hashtags());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <FaHashtag size={24} color={theme.palette.primary.main} />
                    </ListItemIcon>
                    <ListItemText primary="Hashtags" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.badges());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscVerified
                        size={24}
                        color={theme.palette.success.light}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Badges" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      toggleDrawer();
                      push(appRoutes.admin.badgeRequests());
                    }}
                  >
                    <ListItemIcon>
                      <VscUnverified
                        size={24}
                        color={theme.palette.warning.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Badge Requests" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.subscriptions);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <Badge
                        badgeContent={isDelinquent ? 'Unpaid' : 0}
                        color={'error'}
                        showZero={false}
                        overlap="circular"
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      >
                        <VscRocket
                          size={24}
                          color={theme.palette.success.main}
                        />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Subscriptions" />
                  </ListItemButton>
                </>
              )}
            </List>
            <Divider />
          </>
        )}

        {isSubscriptionValue && (
          <>
            <List>
              <ListItemButton onClick={toggleMenu.bind(null, 'rightsmage')}>
                <ListItemText primary="RightsMage" />
                {isMenuOpen.rightsmage ? (
                  <TiArrowSortedDown>
                    <VscSmiley size={24} color={theme.palette.success.light} />
                  </TiArrowSortedDown>
                ) : (
                  <TiArrowSortedUp>
                    <VscSmiley size={24} color={theme.palette.success.light} />
                  </TiArrowSortedUp>
                )}
              </ListItemButton>

              {isSubscriptionValue && isMenuOpen.rightsmage && (
                <>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.overviewDashboard);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscGraph size={24} color="magenta" />
                    </ListItemIcon>
                    <ListItemText primary="Overview Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.propertyDashboard);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscLibrary
                        size={24}
                        color={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Property Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.rightsholderDashboard);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <BsFillPersonLinesFill
                        size={24}
                        color={theme.palette.success.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Rightsholder Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.channelDashboard);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <RiShoppingCart2Line size={24} color="magenta" />
                    </ListItemIcon>
                    <ListItemText primary="Channel Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.retailerDashboard);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <RiShoppingBasketLine
                        size={24}
                        color={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Retailer Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.static.filesUpload);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscCloudUpload
                        size={24}
                        color={theme.palette.success.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Import Reports" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.manualsalesentry);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscEdit size={24} color={theme.palette.error.main} />
                    </ListItemIcon>
                    <ListItemText primary="Manual Sales Entry" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.rightsholders);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <Badge
                        badgeContent={countMissingEmailRightsholder}
                        showZero={false}
                        overlap="circular"
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        sx={{
                          '& .MuiBadge-badge': {
                            background: '#CD8A07',
                          },
                        }}
                      >
                        <FaRegAddressBook
                          size={24}
                          color={theme.palette.success.main}
                        />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Rightsholders" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.properties());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <Badge
                        badgeContent={countMissingProperty}
                        color="error"
                        overlap="circular"
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      >
                        <VscRepo size={24} color={theme.palette.info.main} />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Properties" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.balances);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscLiveShare size={24} color="magenta" />
                    </ListItemIcon>
                    <ListItemText primary="Balances" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.payments);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <FaMoneyBillAlt
                        size={24}
                        color={theme.palette.success.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Payments" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.expenses);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscRepoPush
                        size={24}
                        color={theme.palette.warning.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Expenses" />
                  </ListItemButton>
                  {
                    // RightsMage Basic
                    [
                      planList.four,
                      planList.three,
                      planList.two,
                      planList.one,
                    ].includes(plan) && (
                      <>{/* Should be links for: RightsMage Basic */}</>
                    )
                  }
                  {
                    // RightsMage Pro
                    [planList.four, planList.three, planList.two].includes(
                      plan,
                    ) && <>{/* Should be links for: RightsMage Pro */}</>
                  }
                  {
                    // RightsMage Small Press
                    [planList.four, planList.three].includes(plan) && (
                      <>{/* Should be links for: RightsMage Small Press */}</>
                    )
                  }
                  {/* {
                    // RightsMage Publisher
                    [planList.four].includes(plan) && (
                      <>
                        <ListItemButton
                          onClick={() => {
                            push(appRoutes.admin.advances);
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <VscRepoPull
                              size={24}
                              color={theme.palette.error.main}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Advances" />
                        </ListItemButton>
                        <ListItemButton
                          onClick={() => {
                            push(appRoutes.admin.taxes);
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <RiHandCoinLine
                              size={24}
                              color={theme.palette.warning.main}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Tax Rates" />
                        </ListItemButton>
                        <ListItemButton
                          onClick={() => {
                            push(appRoutes.admin.marketingrates);
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <VscDashboard
                              size={24}
                              color={theme.palette.primary.main}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Marketing Rates" />
                        </ListItemButton>
                        <ListItemButton
                          onClick={() => {
                            push(appRoutes.admin.linkShortener);
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <Badge
                              badgeContent={isCompanySettings ? 0 : '❕'}
                              color="error"
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              sx={{
                                '& .MuiBadge-badge': {
                                  padding: 0,
                                },
                              }}
                            >
                              <VscSettings
                                size={24}
                                color={theme.palette.success.main}
                              />
                            </Badge>
                          </ListItemIcon>
                          <ListItemText primary="Link Shortener" />
                        </ListItemButton>
                      </>
                    )
                  } */}
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.advances);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscRepoPull size={24} color={theme.palette.error.main} />
                    </ListItemIcon>
                    <ListItemText primary="Advances" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.taxes);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <RiHandCoinLine
                        size={24}
                        color={theme.palette.warning.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Tax Rates" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.marketingrates);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscDashboard
                        size={24}
                        color={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Marketing Rates" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.companySettings());
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <Badge
                        badgeContent={isCompanySettings ? 0 : '❕'}
                        color="error"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        sx={{
                          '& .MuiBadge-badge': {
                            padding: 0,
                          },
                        }}
                      >
                        <VscSettings
                          size={24}
                          color={theme.palette.success.main}
                        />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Company Settings" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      push(appRoutes.admin.linkShortener);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <Badge
                        badgeContent={isCompanySettings ? 0 : '❕'}
                        color="error"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        sx={{
                          '& .MuiBadge-badge': {
                            padding: 0,
                          },
                        }}
                      >
                        <VscLink size={24} color="magenta" />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Link Shortener" />
                  </ListItemButton>
                </>
              )}
            </List>
            <Divider />
          </>
        )}

        {!isLoggedIn && (
          <>
            <List>
              <ListItemButton onClick={() => push(appRoutes.auth.login())}>
                <ListItemIcon>
                  <BiLogInCircle size={24} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary="Log in" />
              </ListItemButton>
            </List>
            <List>
              <ListItemButton onClick={() => push(appRoutes.auth.signup)}>
                <ListItemIcon>
                  <FaUserPlus size={24} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary="Register" />
              </ListItemButton>
            </List>
            <Divider />
          </>
        )}

        {isLoggedIn && (
          <>
            <List>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <BiLogOutCircle
                    size={24}
                    color={theme.palette.common.white}
                  />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </ListItemButton>
            </List>

            <Divider />
          </>
        )}

        <List>
          <Box display="flex" alignItems="center" flexDirection="column">
            <img src={siteLogoURL} />
            <Box margin={1}>
              <Typography variant="subtitle2">{siteName}</Typography>
            </Box>
          </Box>
        </List>
      </SimpleDrawer>
    </>
  );
}
