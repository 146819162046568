// Outer
import React from 'react';
import { Box } from '@mui/material';

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout({ children }: DefaultLayoutProps) {
  return <Box margin={2}>{children}</Box>;
}
