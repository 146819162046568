import {useCallback, useState} from 'react';
import {useDebounced} from './useDebounced';

export function useStateDebounced<T>(initialState: T) {
  const [state, _setState] = useState<T>(initialState);
  const [debouncedState, setDebouncedState] = useState<T>(state);
  const setDebouncedDebouncedState = useDebounced(setDebouncedState, 350, []);
  const setState = useCallback(
    (newState: T) => {
      _setState(newState);
      setDebouncedDebouncedState(newState);
    },
    [setDebouncedDebouncedState],
  );

  return {
    state,
    debouncedState,
    setState,
  };
}

export function useExternalStateDebounced<T>(
  initialState: T,
  onChangeDebouncedState: (state: T) => void | Promise<void>,
) {
  const [state, _setState] = useState<T>(initialState);
  const setDebouncedDebouncedState = useDebounced(
    onChangeDebouncedState,
    350,
    [],
  );
  const setState = useCallback(
    (newState: T) => {
      _setState(newState);
      setDebouncedDebouncedState(newState);
    },
    [setDebouncedDebouncedState],
  );

  return {
    state,
    setState,
  };
}
