import {queries} from '@/common/api/operations';
import {appRoutes} from '@/common/config';
import {ApiClient, useApiContext} from '@/common/contexts/ApiContext';
import useQueryParams, {ssrQueryParams} from '@/common/hooks/useQueryParams';
import {useStateDebounced} from '@/common/hooks/useStateDebounced';
import {ClickOrEnter, isClickOrEnter} from '@/common/utils/common';
import {NextPageContext} from 'next';
import {useRouter} from 'next/router';
import {useCallback, useState} from 'react';
import {QueryClient, useQuery} from 'react-query';

export async function ssrSearchListingsAndHashtagsAutocomplete(
  queryClient: QueryClient,
  apiClient: ApiClient,
  ctx: NextPageContext,
) {
  const {search} = ssrQueryParams(ctx);
  const searchText = search ?? '';

  if (searchText) {
    await queryClient.prefetchQuery(
      [queries.search.autocomplete, searchText],
      () => apiClient.search.autocomplete({text: searchText}),
    );
  }
}

export function useSearchListingsAndHashtagsAutocomplete() {
  const {push} = useRouter();
  const {search} = useQueryParams();
  const {apiClient} = useApiContext();
  const {
    state: searchText,
    debouncedState: debouncedSearchText,
    setState: setSearchText,
  } = useStateDebounced(search ?? '');

  const hashtagIndex = debouncedSearchText.lastIndexOf('#');
  const optimizedSearchText =
    hashtagIndex === -1
      ? debouncedSearchText
      : debouncedSearchText.substr(hashtagIndex + 1);

  const searchAutocomplete = useQuery(
    [queries.search.autocomplete, optimizedSearchText],
    () => apiClient.search.autocomplete({text: optimizedSearchText}),
    {keepPreviousData: true, enabled: Boolean(optimizedSearchText)},
  );

  const [isSearching, setIsSearching] = useState(false)

  const pushSearchRoute = (trimedValue: string) => push(appRoutes.home.search(trimedValue));

  const handleSearchTextChange = useCallback(
    (e: React.ChangeEvent<{}>, value: string) => {
      setSearchText(value);
    },
    [setSearchText],
  );
  const handleSelectText = useCallback(
    async (e: React.ChangeEvent<{}>, value: string) => {
      const trimedValue = value.trim();

      if (hashtagIndex === -1) {
        setIsSearching(true)
        setSearchText(trimedValue);
        await pushSearchRoute(trimedValue)
        setIsSearching(false)
      } else {
        setSearchText(
          `${searchText.substr(0, hashtagIndex + 1)}${trimedValue}`,
        );
      }
    },
    [setSearchText, searchText, hashtagIndex, push],
  );
  const handleConfirm = useCallback(
    async (e: ClickOrEnter) => {
      if (isClickOrEnter(e)) {
        setIsSearching(true)
        const trimedValue = searchText.trim();
        setSearchText(trimedValue);
        await pushSearchRoute(trimedValue)
        setIsSearching(false)
      }
    },
    [setSearchText, searchText, push],
  );

  return {
    isSearching,
    isSearchingOptions: searchAutocomplete.isLoading,
    searchText,
    autocompleteResults: searchAutocomplete.data?.results ?? [],
    handleSearchTextChange,
    handleSelectText,
    handleConfirm,
  };
}
